<template>
    <div class="integration_programme main_padding">
      <!-- <div class="top_text w1400">
        <h5 class="sub_title txt-bold">
          業務流程及工程業務範圍
        </h5>
        <p>
          麒麟工程從規劃階段就為客戶提供工廠建設和製造設備建設等各種問題的支持，創建、設計、施工、調試基本計劃，
          在藥品驗證中而不是以IQ-OQ結束，從運行保護後授予進一步的PQ植物預防，創建定期驗證計劃，其實施等一致在一個項目制度下並明確責任制，我們站在用戶的角度，
          以“用戶工程”的身份進行廣泛的操作。
        </p>
      </div> -->

      <div class="w1400">
        <img src="@/assets/img/process/02_integration_programme/02_img.png" alt="">
      </div>
    </div>
</template>

<script>
export default {
  name: 'IntegrationProgramme',
  components: {
  },
  data() {
    return {
    };
  },
};
</script>
